import { openPremiumDialog, useMsTeamsSelector, usePermissionsSelector, usePremiumSelector, useTranslate, userHasRightsToEdit } from "front";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../translations";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
export const useLogic = () => {
    const t = useTranslate(translations);
    const { calendar, mutateCalendar, mutateCalendarIsLoading, refecthCalendar } = useCalendarCache();
    const { userMail } = useMsTeamsSelector("userMail");
    const { userPerm } = usePermissionsSelector("userPerm");
    const { isPremium } = usePremiumSelector("isPremium");
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState({
        listItems: [],
        searchValue: "",
        readonly: false,
        isLoadingTags: false,
        isLoadingTagsFailed: false,
        newTag: "",
        tagsError: undefined,
        selectedTag: undefined,
        tagsUpdateError: undefined,
        tagToDelete: undefined
    });
    useEffect(() => {
        if (!calendar)
            return;
        setState((prevState) => ({
            ...prevState,
            listItems: calendar?.tags ?? [],
            readonly: !userHasRightsToEdit(userPerm)
        }));
    }, [calendar, userPerm]);
    const returnToCalendar = () => {
        if (!calendar)
            return;
        navigate("/calendars/" + calendar.id);
    };
    const searchOnChange = (event, data) => {
        setState((prevState) => ({ ...prevState, searchValue: data?.value ?? "" }));
    };
    const activeEditItem = (index, value) => {
        const selectedTag = { index, value };
        setState((prevState) => ({ ...prevState, selectedTag }));
    };
    const addTagOnChange = (event, data) => {
        if (data.value.length > 50) {
            setState((prevState) => ({ ...prevState, tagsError: t("Tag:ErrorFieldTooLong") }));
        }
        else if (state.listItems.some((item) => item?.toLocaleLowerCase()?.trim() == data?.value?.toLocaleLowerCase()?.trim()))
            setState((prevState) => ({ ...prevState, tagsError: t("Tag:ErrorTagAlreadyExist") }));
        else
            setState((prevState) => ({ ...prevState, tagsError: undefined }));
        setState((prevState) => ({ ...prevState, newTag: data?.value ?? "" }));
    };
    const editOnChange = (event, data) => {
        if (data == null || data?.value.trim() == "") {
            setState((prevState) => ({ ...prevState, tagsUpdateError: t("Tag:ErrorFieldRequired") }));
        }
        else if (data.value.length > 50) {
            setState((prevState) => ({ ...prevState, tagsUpdateError: t("Tag:ErrorFieldTooLong") }));
        }
        else if (state.listItems.some((item) => item?.toLocaleLowerCase()?.trim() == data?.value?.toLocaleLowerCase()?.trim() && data?.value?.toLocaleLowerCase()?.trim() !== state.listItems[state.selectedTag.index].toLocaleLowerCase()?.trim())) {
            setState((prevState) => ({ ...prevState, tagsUpdateError: t("Tag:ErrorTagAlreadyExist") }));
        }
        else
            setState((prevState) => ({ ...prevState, tagsUpdateError: undefined }));
        setState((prevState) => ({
            ...prevState,
            selectedTag: { index: prevState.selectedTag?.index ?? 0, value: data?.value ?? "" }
        }));
    };
    const cancelUpdate = () => {
        setState((prevState) => ({
            ...prevState,
            selectedTag: undefined,
            tagsUpdateError: undefined,
        }));
    };
    const handleEditItem = async (tagString) => {
        if (!isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            return;
        }
        if (state.selectedTag == undefined || state.selectedTag.value == "" || state.selectedTag?.value.trim() == "") {
            setState((prevState) => ({ ...prevState, tagsUpdateError: t("Tag:ErrorFieldRequired") }));
            return;
        }
        else if (state.listItems.some((item) => item?.toLocaleLowerCase()?.trim() == state.selectedTag?.value.toLocaleLowerCase()?.trim()) && state.selectedTag?.value.toLocaleLowerCase()?.trim() !== state.listItems[state.selectedTag.index].toLocaleLowerCase()?.trim()) {
            setState((prevState) => ({ ...prevState, tagsUpdateError: t("Tag:ErrorTagAlreadyExist") }));
            return;
        }
        const tags = calendar?.tags ?? [tagString];
        const updatedTag = tags.map(t => {
            if (t == tagString) {
                t = state.selectedTag?.value.trim() ?? "";
            }
            return t;
        });
        let updatedCalendar = { ...calendar,
            tags: updatedTag
        };
        await mutateCalendar(updatedCalendar, {
            onSuccess: () => {
                setState((prevState) => ({ ...prevState, selectedTag: undefined, tagsUpdateError: undefined }));
            }
        });
    };
    const openDeleteDialog = (tagString) => {
        if (!isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            return;
        }
        setState((prevState) => ({ ...prevState, tagToDelete: tagString }));
    };
    const closeDeleteDialog = () => {
        if (!isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            return;
        }
        setState((prev) => ({ ...prev, tagToDelete: undefined }));
    };
    const handleDeleteItem = async () => {
        if (!isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            return;
        }
        if (!state.tagToDelete)
            return;
        const tags = calendar?.tags ?? [state.tagToDelete];
        const updatedTag = tags.filter(t => t != state.tagToDelete);
        let updatedCalendar = { ...calendar,
            tags: updatedTag
        };
        await mutateCalendar(updatedCalendar, {
            onSuccess: () => {
                setState((prevState) => ({ ...prevState, tagToDelete: undefined }));
            }
        });
    };
    /// Handle the creation of a new tag
    const handleAddItem = async () => {
        if (!isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            return;
        }
        if (state.newTag == null || state.newTag == "") {
            setState((prevState) => ({ ...prevState, tagsError: t("Tag:ErrorFieldRequired") }));
            return;
        }
        else if (state.listItems.some((item) => item?.toLocaleLowerCase()?.trim() == state.newTag?.toLocaleLowerCase()?.trim())) {
            setState((prevState) => ({ ...prevState, tagsError: t("Tag:ErrorTagAlreadyExist") }));
            return;
        }
        let updatedCalendar = { ...calendar,
            tags: [...calendar?.tags || [], state.newTag.trim()]
        };
        await mutateCalendar(updatedCalendar, {
            onSuccess: () => {
                setState((prevState) => ({ ...prevState, newTag: "", tagsError: undefined }));
            }
        });
    };
    return {
        state,
        returnToCalendar,
        searchOnChange,
        handleAddItem,
        handleEditItem,
        handleDeleteItem,
        addTagOnChange,
        editOnChange,
        activeEditItem,
        cancelUpdate,
        openDeleteDialog,
        closeDeleteDialog,
        mutateCalendarIsLoading
    };
};
