export const tooltipOpenChange = (isOnMobile, data, dispatch) => {
    if (isOnMobile) {
        if (data.open) {
            document.getElementById("tooltip-content-upgrade").addEventListener("touchstart", () => { dispatch(); });
        }
        else {
            document.getElementById("tooltip-content-upgrade").removeEventListener("touchstart", () => { dispatch(); });
        }
    }
};
